import { render, staticRenderFns } from "./KnowYourRiskAppetite.vue?vue&type=template&id=4c5be266&scoped=true&"
import script from "./KnowYourRiskAppetite.js?vue&type=script&lang=js&"
export * from "./KnowYourRiskAppetite.js?vue&type=script&lang=js&"
import style0 from "./KnowYourRiskAppetite.vue?vue&type=style&index=0&id=4c5be266&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c5be266",
  null
  
)

export default component.exports